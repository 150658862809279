import { Store } from 'vuex';
import { PaginationModel } from '@/store/models/PaginationModel';
import { SearchRequestModel } from '@/store/models/OrderlineModel';

export function PaginationCommand(
  typeName: string,
  store: Store<any>,
  model: PaginationModel
) {
  switch (typeName) {
    case 'items': {
      store.commit('items/setCurrentPage', model.CurrentPage);
      if (model.SearchModel) {
        store.dispatch('items/GetSearchedItemsByEntity', model);
        return;
      }
      store.dispatch('items/GetItems', model);
      return;
    }
    case 'orderlines': {
      store.commit('orderlines/setCurrentPage', model.CurrentPage);
      if (model.SearchModel) {
        store.dispatch('orderlines/GetSearchedOrderlinesByEntity', model);
      } else {
        store.dispatch('orderlines/GetOrderlinePagination', model);
      }
      return;
    }
  }
}
