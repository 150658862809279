import { StringHelper } from './StringHelper';

export const SearchHelper = {
  FormatSuggestions(suggestionList: any[], searchText: string) {
    let matchedItems = suggestionList.filter(
      s =>
        this.DoesMatch(s.Description, searchText) ||
        this.DoesMatch(s.PartNumber, searchText)
    );
    let matchedPOs = suggestionList.filter(s => this.DoesMatch(s.PO, searchText));
    if (!matchedItems[0]) matchedItems[0] = { NoItemFound: true };
    if (!matchedPOs[0]) matchedPOs[0] = { NoPOFound: true };
    let list = [
      { header: 'Items' },
      ...matchedItems,
      { header: 'Orders' },
      ...matchedPOs
    ];
    return list;
  },
  DoesMatch(firstItem: string, secondItem: string) {
    return StringHelper.doesFirstStringContainSecond(firstItem, secondItem);
  },
  FormatMatchedString(stringToChange: string, searchText: string) {
    if (!searchText) return stringToChange;
    let location = stringToChange.toLowerCase().search(searchText.trim().toLowerCase());
    let stringStart = stringToChange.substring(0, location);
    let stringEnd = stringToChange.substring(location + searchText.trim().length, 9999);
    let stringMatch = stringToChange.substring(
      location,
      location + searchText.trim().length
    );
    return `${stringStart}<span class="roboto-bold">${stringMatch}</span>${stringEnd}`;
  }
};
