
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { State, Action, Getter, Mutation } from 'vuex-class';
import { Item, ItemFilterType } from '@/store/models/ItemModel';
import {
  Orderline,
  OrderlineDetails,
  OrderlinePurchaseStateTypes,
  RushOption,
  SearchRequestModel
} from '@/store/models/OrderlineModel';
import { ItemService } from '../../services/item-service';
import { OrdersService } from '@/services/orders-service';
import { ErrorService } from '@/services/error-service';
import { BuildItemAlertMessage } from '@/helpers/ObjectHelper';
import DefaultLayout from '@/components/DefaultLayout.vue';
import ItemDialog from '@/components/ItemDialog.vue';
import J1LPagination from '@/components/J1LPagination.vue';
import ItemCard from '@/components/cardComponents/ItemCard.vue';
import J1lImg from '@/components/J1lImg.vue';
import J1lSearchBox from '@/components/J1lSearchBox.vue';
import NoItemOrder from '@/components/NoItemOrder.vue';
import AlertComponent from '@/components/AlertLayout.vue';
import { StringHelper } from '@/helpers/StringHelper';
import { SearchHelper } from '@/helpers/SearchHelper';
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import ItemBulkActions from '@/components/ItemBulkActions.vue';
import { CookieHelpers } from '@/helpers/CookieHelper';
import { ConstValues } from '@/ConstValues';

@Component({
  components: {
    DefaultLayout,
    ItemDialog,
    J1LPagination,
    ItemCard,
    J1lImg,
    J1lSearchBox,
    AlertComponent,
    NoItemOrder,
    ItemBulkActions
  }
})
export default class ManageItems extends Vue {
  $refs!: {
    defaultActions: HTMLFormElement;
    ItemDialog: HTMLFormElement;
    itemSearchInput: HTMLFontElement;
    itemCard: HTMLFormElement;
    ItemBulkActions: HTMLFormElement;
    J1LPagination: HTMLFormElement;
  };

  /* Properties */
  /* Store Actions */
  @State('shouldReconcileQueuedItems', { namespace: 'items' })
  shouldReconcileQueuedItems: any;
  @Getter('getErrorLoadingItems', { namespace: 'items' })
  getErrorLoadingItems!: boolean;
  @Getter('getLoadingState', { namespace: 'items' })
  areItemsLoading: any;
  @Getter('getItemPartNumber', { namespace: 'items' })
  getPartNumbers: any;
  @Getter('isTransparencyCustomer', { namespace: 'customer' })
  isTransparencyCustomer: boolean;
  @Getter('getItemFilterCountContainer', { namespace: 'items' })
  getItemFilterCountContainer: any;
  @Getter('getUserValidation', { namespace: 'profile' })
  userValidation!: any;
  @Getter('getDisplayStyle', { namespace: 'customer' })
  displayStyle!: string;
  @Action('ClearCurrentOrder', { namespace: 'orderlines' })
  clearCurrentOrder!: any;
  @Getter('getAllProcessingImageIds', { namespace: 'items' })
  processingImageItemIds: any;
  @Getter('items', { namespace: 'items' })
  items!: Item[];
  @Getter('getFilteredItem', { namespace: 'items' })
  GetFilteredItem: any;
  @Getter('doesCustomerHaveItems', { namespace: 'items' })
  doesCustomerHaveItems!: boolean;
  @Mutation('setHasItems', { namespace: 'items' })
  setHasItems: any;

  /* Watchers */
  @Watch('getErrorLoadingItems')
  onGetErrorLoadingItemsChange(val: boolean) {
    if (this.getErrorLoadingItems) {
      SetSnackBar('Error loading your items');
    }
  }
  @Watch('getItemFilterCountContainer')
  onFilterCountChange(val: any) {
    this.refreshFilterOptionCount();
  }
  @Watch('search')
  searching(val: string) {
    if (val) {
      this.refreshImageForSearch();
    }
  }
  @Watch('searchText')
  onSearchTextChange(val: string) {
    val && val !== this.selectedSuggestion && this.getSuggestions();
  }
  @Watch('GetFilteredItem')
  onFilteredItemChange(val: any) {
    if (val[0]) {
      this.activeChip = 0;
      this.isItemFiltered = true;
      this.filteredItems = val;
    }
  }

  @Watch('selectedSuggestion')
  async onSelect(searchSuggestion: any) {
    this.filteredItems = [];
    this.isItemFiltered = true;
    this.activeChip = 0;
    this.$store.commit('items/setFilteringType', ItemFilterType.Everything);
    if (!searchSuggestion) {
      this.clearFilteredItems();
      return;
    }
    let searchValue = searchSuggestion;
    if (searchSuggestion.PartNumber) searchValue = searchSuggestion.ItemId;
    if (searchSuggestion.PO) searchValue = searchSuggestion.PO;
    let typeValue = -1;
    if (searchSuggestion.PartNumber) typeValue = 0;
    if (searchSuggestion.PO) typeValue = 1;
    this.searchBySearchValue({
      value: searchValue,
      isEnter: true,
      TypeValue: typeValue
    });

    this.$refs.itemSearchInput.blur();
  }
  @Watch('items')
  onItemsChanged() {
    if (this.isItemFiltered) {
      this.filteredItems.forEach(item => {
        let newItem = this.items.find(i => i.Id == item.Id)!;
        Object.assign(item, newItem);
      });
    }
  }
  @Watch('isInBulkEditMode')
  onIsInBulkEditModeChanged() {
    this.$refs.defaultActions.setAppBarElevation(!this.isInBulkEditMode);
  }

  /* Data */
  isItemFiltered: boolean = false;
  areChipsGettingData: boolean = false;
  shouldRefreshImg: boolean = false;
  shouldStopPagination: boolean = false;
  refreshImg: boolean = true;
  search: string = '';
  suggestions: any[] = [];
  selectedSuggestion: any = null;
  isLoading: boolean = false;
  typingTimer: any;
  doneTypingInterval: number = 250;
  searchText: string = 'PartNumber';
  searchValue: string = '';
  filteredItems: Item[] = [];
  activeChip: number = 0;
  searchModel: SearchRequestModel | null = null;
  selectedDisplayStyle: number = 0;
  currentFilter: {
    id: number;
    text: string;
    value: string;
    count: number;
    isSelected: true;
  } = {
    id: 0,
    text: 'Everything',
    value: 'Everything',
    count: 0,
    isSelected: true
  };
  filterOptions: any[] = [
    {
      id: 0,
      text: 'Everything',
      value: 'Everything',
      count: 0,
      isSelected: true
    },
    {
      id: 1,
      text: 'On Order',
      value: 'OnOrder',
      count: 0,
      isSelected: false
    },
    {
      id: 2,
      text: 'On Hold',
      value: 'OnHold',
      count: 0,
      isSelected: false
    },
    {
      id: 3,
      text: 'Sign-Offs',
      value: 'SignOffs',
      count: 0,
      isSelected: false
    }
  ];
  newItemActions: any[] = [
    { title: 'New Transparency Item', icon: 'mdi-plus' },
    { title: 'New Non Transparency Item', icon: 'mdi-plus' }
  ];

  selectedItemIdAndType: any = null;

  /* Methods */
  onDisplayStyleChange(data) {
    switch (data) {
      case 0:
        this.$store.commit('customer/setDisplayStyle', ConstValues.DISPLAY_STYLE_CARD);
        CookieHelpers.setCookie(
          ConstValues.COOKIE_NAME_DISPLAY_STYLE,
          ConstValues.DISPLAY_STYLE_CARD
        );
        break;
      case 1:
        this.$store.commit('customer/setDisplayStyle', ConstValues.DISPLAY_STYLE_LIST);
        CookieHelpers.setCookie(
          ConstValues.COOKIE_NAME_DISPLAY_STYLE,
          ConstValues.DISPLAY_STYLE_LIST
        );
        break;
    }
  }
  refreshFilterOptionCount() {
    this.filterOptions.map((fo, i) => {
      fo.count = this.getItemFilterCountContainer[fo.value];
    });
  }
  searchBySearchValue(response: any, shouldBuildOrderlineDisplay: boolean = true) {
    if (response && response.value) {
      this.getFilteredItems(response);
    } else {
      this.clearFilteredItems();
    }
  }
  searchBySearchQuery() {
    if (this.selectedSuggestion) return;
    if (this.searchText) this.suggestions.push(this.searchText);
    this.selectedSuggestion = this.suggestions[this.suggestions.length - 1];
    if (!this.searchText) {
      this.currentFilter = this.filterOptions[0];
      this.clearSearch();
      this.$refs.itemSearchInput.blur();
    } else {
      this.refreshPagination();
    }
  }
  getFilteredItems(response: any) {
    this.$store.commit('items/setCurrentPage', 1);
    this.refreshPagination();
    this.$store.dispatch('items/ClearSearchOrder');
    this.currentFilter = this.filterOptions[0];
    this.searchModel = new SearchRequestModel({
      SearchValue: response.value[0] ? response.value : response.value.trim(),
      TypeValue: response.TypeValue
    });
  }
  clearFilteredItems() {
    this.$store.dispatch('items/ClearSearchOrder');
    this.searchModel = null;
    this.isItemFiltered = false;
  }
  refreshPagination() {
    this.shouldStopPagination = true;
    setTimeout(() => {
      this.shouldStopPagination = false;
    }, 1);
  }
  async onItemFilterOptionClick(value: any) {
    this.filterOptions[value].isSelected = true;
    this.areChipsGettingData = true;
    switch (value) {
      case 0:
        this.$store.commit('items/setFilteringType', ItemFilterType.Everything);
        this.isItemFiltered = false;
        break;
      case 1:
        await this.$store.dispatch('items/GetOnOrderItem');
        break;
      case 2:
        this.areChipsGettingData = true;
        await this.$store.dispatch('items/GetOnHoldItem');

        break;
      case 3:
        this.areChipsGettingData = true;
        await this.$store.dispatch('items/GetSignOffItem');
        break;
    }
    this.areChipsGettingData = false;
  }
  async getSuggestions() {
    this.isLoading = true;
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(async () => {
      try {
        let model = {
          Take: 10,
          Skip: 0,
          Query: !this.searchText ? this.searchText : this.searchText.trim()
        };
        const response = await OrdersService.GetAutocompleteSuggestionsBySearchQuery(
          model
        );
        this.suggestions = [];
        this.suggestions = this.formatSuggestions(response.data);
      } catch (err) {
        console.log(err);
      }
      this.isLoading = false;
    }, this.doneTypingInterval);
  }
  doesMatch(firstItem: string, secondItem: string) {
    return StringHelper.doesFirstStringContainSecond(firstItem, secondItem);
  }

  formatSuggestions(suggestionList: any[]) {
    let matchedItems = suggestionList.filter(
      s =>
        this.doesMatch(s.Description, this.searchText) ||
        this.doesMatch(s.PartNumber, this.searchText)
    );
    let matchedPOs = suggestionList.filter(s => this.doesMatch(s.PO, this.searchText));
    if (!matchedItems[0]) matchedItems[0] = { NoItemFound: true };
    if (!matchedPOs[0]) matchedPOs[0] = { NoPOFound: true };
    let list = [
      { header: 'Items' },
      ...matchedItems,
      { header: 'Orders' },
      ...matchedPOs
    ];
    return list;
  }
  formatMatchedString(stringToChange: string) {
    return SearchHelper.FormatMatchedString(stringToChange, this.searchText);
  }

  filterObject(item: any, queryText: string, itemText: string) {
    return (
      item.PartNumber.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
      item.Description.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
    );
  }
  refreshImageForSearch() {
    this.refreshImg = false;
    setTimeout(() => (this.refreshImg = true), 1);
  }
  removeDuplicateParts(item: Item) {
    let affectedItems = this.items.filter(i => i.PartNumber == item.PartNumber);
    for (const i of affectedItems) {
      if (i.Id !== item.Id) {
        this.$store.dispatch('items/refreshItem', i.Id);
      }
    }
  }
  async handleEditItem(item: any, isAdding: boolean) {
    if (item && !isAdding) {
      this.clearSearch();
      await this.$store.dispatch('items/refreshItem', item.itemId);
      this.reGrabItemFromStore(item.itemId);
      this.isItemVersionCorrect(item.itemId, item.expectedVersion);
      if (this.shouldRefreshImg) {
        this.$store.dispatch('items/addProcessImageItemId', item.itemId);
        this.shouldRefreshImg = false;
      }
      if (item.didChangePartNumber) {
        this.removeDuplicateParts(item.affectedItem);
      }
    }
  }
  isItemVersionCorrect(itemId: string, expectedVersion: number) {
    let item = this.items.find(i => i.Id == itemId);
    if (item && item.Version == expectedVersion) {
      console.log(`item ${itemId} version ${item.Version} matches expected Version`);
    } else {
      ErrorService.LogError(
        `item ${itemId} does not match expected Version ${expectedVersion}`
      );
    }
  }
  async createItem(response: any) {
    this.$refs.ItemDialog.closeDialog();
    if (response.didAccept) {
      this.setHasItems(true);
      await this.$store.dispatch('items/refreshItem', response.itemId);
      this.$store.dispatch('items/addProcessImageItemId', response.itemId);
    }
  }
  setShouldItemRefreshToTrue(response: boolean) {
    this.shouldRefreshImg = response;
  }

  goToOrderView(po: string = '') {
    this.clearCurrentOrder();
    this.$router.push({ name: 'OrderView' });
  }

  searchItem(partNumber: string) {
    let searchResult = this.getPartNumbers.find((p: Item) => p.PartNumber == partNumber);
    if (searchResult) {
      // this.selectedSuggestion.push(partNumber);
      this.searchText = partNumber;
      this.searchBySearchQuery();
    }
  }
  handleNewItemActionEvent(action: any) {
    switch (action) {
      case 'New Non Transparency Item':
        this.$refs.ItemDialog.openDialog();
        break;
      case 'New Transparency Item':
        this.$router.push({ name: 'AddEditTransparencyItem' });
        break;
    }
  }
  AddNewItemSelected() {
    if (this.isTransparencyCustomer) {
      this.$router.push({ name: 'AddEditTransparencyItem' });
    } else {
      this.$refs.ItemDialog.openDialog();
    }
  }
  clearSearch() {
    this.selectedSuggestion = null;
    this.clearFilteredItems();
  }
  onMultipleItemSelectClose() {
    this.displayedItems.forEach(i => (i.IsSelected = false));
  }

  async loadItemCard(response: any) {
    if (this.processingImageItemIds.length > 0) {
      await this.$store.dispatch('items/removeProcessImageItemId', response.itemId);
    }
    this.$store.dispatch('items/refreshItem', response.itemId);
  }
  get displayedItems() {
    return this.isItemFiltered ? this.filteredItems : this.items;
  }
  get filteredfilterOptions() {
    return this.filterOptions.filter(f => f.count > 0);
  }
  get isInBulkEditMode() {
    return !!this.selectedItems[0];
  }
  get selectedItems() {
    return this.items.filter(i => i.IsSelected);
  }
  dropZoneEvent() {
    console.log('File Drop');
  }
  reGrabItemFromStore(itemId: string) {
    let itemFromStore = this.items.find(i => i.Id === itemId);
    if (itemFromStore && this.isItemFiltered) {
      this.filteredItems = this.filteredItems.filter(i => i.Id !== itemId);
      this.displayedItems.unshift(itemFromStore);
    }
  }
  handlePartNumberSearch(partnumber: string) {
    // this.selectedSuggestion = partnumber;
    this.searchText = partnumber;
    this.searchBySearchQuery();
    // this.$nextTick(() => {
    //   this.searchText = partnumber;
    //   this.searchBySearchQuery();
    // });
  }

  /* Loaders */
  /* Mounted */
  async mounted() {
    this.$store.dispatch('items/refreshItemFilterCount');
    this.currentFilter = this.filterOptions[0];
    this.onItemFilterOptionClick(0);
    if (this.$route.params.partnumber) {
      this.handlePartNumberSearch(this.$route.params.partnumber);
    } else {
      this.getSuggestions();
    }
    this.selectedDisplayStyle = this.displayStyle === 'card' ? 0 : 1;
    if (this.$route.query.refresh) {
      this.$refs.J1LPagination.refreshPage();
      this.$nextTick(() => {
        this.$router.replace({});
      });
    }
  }
  /* Created */
}
